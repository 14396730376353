<template>
    <div class="organization-card">
        <div class="name-image">
            <div class="name">{{ organization.name }}</div>
            <img v-if="regionImage" :src="regionImage" alt="Region image" />
        </div>

        <div class="region">{{ organization.city }}</div>

        <hr />

        <div class="type">{{ organization.okvedName }}</div>

        <div class="rating-amount">
            <div class="rating">
                <span style="color: #C4C4C4; margin-right: 5px">-</span>
                <v-rating
                    :value="3.7"
                    background-color="orange lighten-3"
                    color="orange"
                    large
                    half-increments
                    small
                ></v-rating>
            </div>

            <!--            <div class="people-amount">-->
            <!--                <v-icon color="primary">group_black</v-icon>-->
            <!--                <span style="margin-left: 8px; color: #263238">-</span>-->
            <!--            </div>-->
        </div>

        <div class="location">
            <v-icon color="primary">location_on_black</v-icon>
            <span style="margin-left: 8px; color: #263238;">
                {{ organization.address }}
            </span>
        </div>

        <div
            style="display: flex; align-items: center; justify-content: space-between; margin-top: 21px;"
        >
            <div class="status" style="display: flex; align-items: center">
                <v-icon :color="organization.status === 'Действующая' ? '#059669' : '#F43F5E'">
                    {{ organization.status === "Действующая" ? "check_circle" : "cancel" }}
                </v-icon>
                <div
                    class="status-value ml-2"
                    :style="
                        `color: ${organization.status === 'Действующая' ? '#059669' : '#F43F5E'}`
                    "
                >
                    {{ organization.status }}
                </div>
            </div>

            <router-link class="more" :to="organizationPageLink" v-if="showMore">
                <span style="margin-right: 10px; color: #0284C7;">Подробно</span>
                <v-icon color="primary">east_black</v-icon>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrganizationCard",
    props: {
        showMore: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        organization() {
            return this.data.organization || {};
        },
        regionImage() {
            const imageName = this.data?.region?.pathImage;
            if (imageName) {
                return `https://atom-api.kovalev.team/region-image/${imageName}`;
            }

            return "";
        },
		organizationPageLink() {
        	const queryParams = new URLSearchParams();

        	let organizationSite = this.organization.site;
        	if (organizationSite) {
        		if (organizationSite.includes(",")) {
        			organizationSite = organizationSite.split(",")[0];
				}

        		queryParams.append("site", organizationSite);
			}

        	return `/organization/${this.organization.id}?${queryParams}`;
		}
    }
};
</script>

<style scoped lang="scss">
.organization-card {
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    background: white;
    padding: 26px 32px;

    .name-image {
        display: flex;

        .name {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            flex: 1;
        }

        img {
            width: 64px;
        }
    }

    .region {
        color: #263238;
        margin-top: 12px;
    }

    .type {
        margin-top: 14px;
        color: #263238;
    }

    .rating-amount {
        margin-top: 18px;
        display: flex;
        align-items: center;

        .rating {
            display: flex;
            align-items: center;
        }

        .people-amount {
            margin-left: 44px;
            display: flex;
            align-items: center;

            .v-icon.v-icon {
                display: block;
                width: 24px;
            }
        }
    }

    .location {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .v-icon.v-icon {
            display: block;
            width: 24px;
        }
    }

    .more {
        text-align: right;
        display: block;

        .v-icon.v-icon {
            display: inline-block;
            width: 24px;
        }
    }
}
</style>
