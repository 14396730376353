<template>
    <div style="background: #F9FAFB; min-height: 100vh">
		<Header flat />

		<div class="wrapper">
            <slot />
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header";
export default {
    name: "Layout",
    components: { Header }
};
</script>

<style scoped></style>
