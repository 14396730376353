import httpClient from "@/client/httpClient";
import { constructUrlWithQueryParams } from "@/client/query-params-utils";

export class MapService {
    static API_ENDPOINT = "/map";

    static async findRegionByCity(cityName) {
        const response = await httpClient().get(
            `${this.API_ENDPOINT}/findRegionByCity?city=${cityName}`
        );

        return response.data;
    }

    static async findCitiesByRegion(regionNames) {
        const url = constructUrlWithQueryParams(`${this.API_ENDPOINT}/findCityByRegion`, {
            regions: regionNames
        });

        const response = await httpClient().get(url);

        return response.data;
    }
}
