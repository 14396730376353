<template>
    <Layout class="organizations-list">
        <v-text-field
            :value="filters.orgName"
            filled
            hide-details
            outlined
            label="Название организации"
            class="mt-4"
            @input="setOrgName"
        />

        <div class="d-flex mt-4 line-filters">
            <v-autocomplete
                :value="filters.regions"
                filled
                hide-details
                outlined
                label="Регион"
                multiple
                :items="regionOptions"
                style="width: 27%"
                @input="setRegionAndSearchCities"
            />
            <v-autocomplete
                v-model="filters.cities"
                style="width: 27%"
                filled
                hide-details
                outlined
                label="Город"
                multiple
                :items="citiesOptions"
            />
            <v-autocomplete
                v-model="filters.numberOfPeople"
                filled
                hide-details
                outlined
                label="Численность"
                clearable
                :items="$options.populationOptions"
                style="width: 19%"
            />
            <v-autocomplete
                v-model="filters.types"
                filled
                hide-details
                outlined
                multiple
                label="Тип учреждения"
                clearable
                :items="orgTypeOptions"
                style="width: 27%"
            />
        </div>

        <div class="d-flex align-center mt-2 checkboxes">
            <v-checkbox
                v-model="filters.isAtomCity"
                label="Атомный город"
                hide-details
                color="primary"
            />
            <v-checkbox
                v-model="filters.isRaexOrg"
                label="В рейтинге RAEX"
                hide-details
                color="primary"
                class="ml-4"
            />

            <v-btn color="primary" style="margin-left: auto" depressed @click="clearFilters">
				Очистить фильтры
			</v-btn>
        </div>

        <div v-if="!isLoading" class="organization-cards">
            <OrganizationCard
                v-for="organization in organizations"
                :data="organization"
                show-more
            />
        </div>

        <v-progress-circular
            v-if="isLoading"
            style="margin: 20px auto; width: 100%"
            :size="50"
            indeterminate
            color="primary"
        />

        <v-pagination
            :value="page"
            :length="organizationsTotalLength"
            :total-visible="10"
            class="mt-4"
            @input="changePage"
        />
    </Layout>
</template>

<script>
import Header from "@/components/Header";
import Layout from "@/components/Layout";
import RegionService from "@/services/RegionService";
import { OrganizationTypesService } from "@/services/OrganizationTypesService";
import OrganizationCard from "@/components/OrganizationCard";
import { OrganizationService } from "@/services/OrganizationService";
import { MapService } from "@/services/MapService";
import { debounce } from "debounce";

const createDefaultFilters = () => ({
	orgName: null,
	regions: [],
	cities: [],
	numberOfPeople: null,
	types: [],
	isAtomCity: false,
	isRaexOrg: false,
	countRow: 10
})

export default {
    name: "OrganizationsList",
    components: { OrganizationCard, Layout, Header },
    populationOptions: [
        {
            value: 1,
            text: "< 100 тыс."
        },
        {
            value: 2,
            text: "от 100 до 500 тыс."
        },
        {
            value: 3,
            text: "от 500 тыс до 1 млн."
        },
        {
            value: 4,
            text: "> 1 млн"
        }
    ],
    data() {
        return {
            regionOptions: [],
            citiesOptions: [],
            orgTypeOptions: [],
            filters: createDefaultFilters(),
            page: 1,
            organizations: [],
            organizationsTotalLength: 0,
            isLoading: false
        };
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.page = 1;
                this.loadOrganizations();
            }
        }
    },
    mounted() {
        RegionService.getAll().then(regions => {
            this.regionOptions = regions.map(r => r.nameRu);
        });

        OrganizationTypesService.getAllTypes().then(types => {
            this.orgTypeOptions = types.map(t => ({
                value: t.id,
                text: t.description
            }));
        });

        this.loadOrganizations();
    },
    methods: {
        loadOrganizations() {
            this.isLoading = true;

            OrganizationService.getAll({ ...this.filters, skip: this.page }).then(response => {
                this.isLoading = false;
                this.organizations = response.organizationDto;
                this.organizationsTotalLength = response.totalCountRows;
            });
        },
        async setRegionAndSearchCities(regions) {
            if (regions && regions.length) {
                this.citiesOptions = await MapService.findCitiesByRegion(regions);
            }

            this.filters = {
                ...this.filters,
                cities: [],
                regions
            };
        },
        setOrgName: debounce(function(value) {
            this.filters = {
                ...this.filters,
                orgName: value
            };
        }, 500),
        changePage(newPage) {
            this.page = newPage;
            this.loadOrganizations();
        },
		clearFilters() {
        	this.page = 1;
        	this.filters = createDefaultFilters();
		}
    }
};
</script>

<style scoped lang="scss">
.organization-cards {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
</style>

<style lang="scss">
.organization-cards {
    .v-rating .v-icon {
        padding: 0;
    }
}

.organizations-list .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: white !important;
}

.organizations-list .checkboxes .v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.line-filters {
    .v-text-field--outlined:not(:last-child) fieldset {
        border-right: none;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .v-text-field--outlined:not(:first-child) fieldset {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}
</style>
